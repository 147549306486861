import { Typography, useTheme } from "@mui/material";
import React from "react";

const Logo = () => {
  const { palette } = useTheme();
  return (
    <img
      src="https://i.imgur.com/PI1z2PK.png"
      alt="logo"
      style={{ width: "100%", maxWidth: 160 }}
    />
  );
};

export default Logo;
