import React, { useContext } from "react";
import { UserContext } from "../../context/user.context";
import { hasPermission } from "../../utils/utils";

export default function WithPermissions(props: any) {
  const { permissions: userPermissions } = useContext(UserContext);

  if (props.orCondition && props.orCondition()) {
    return <>{props.children}</>;
  }

  if (
    props.permissions &&
    hasPermission({
      userPermissions: userPermissions,
      requiredPermissions: props.permissions,
    })
  ) {
    return <>{props.children}</>;
  }

  return <></>;
}
