import { Box, CircularProgress } from "@mui/material";
import SidebarLayout from "../../layout/sidebar/sidebar.layout";
import { GridLoadingOverlay } from "@mui/x-data-grid";

export default function LoadingScreen() {
  // Test aici
  return (
    <SidebarLayout className="loading">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 220px)",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    </SidebarLayout>
  );
}
