import { customAxios } from "../context/app.context";
import { ApiResponse } from "../typescript/shared.types";

// Register user
export const getUsersRequest = async (): Promise<ApiResponse> => {
  const response = await customAxios.post("/admin/users/getUsers");

  return response.data;
};

// get attempts request
export const getAttemptsRequest = async (): Promise<ApiResponse> => {
  const response = await customAxios.post("/riddle/getAttemptsRequest");

  return response.data;
};
