import { FileType, PermissionType } from "../typescript/shared.types";

export function randomInt(min: number, max: number): number {
  return Math.round(min + Math.random() * (max - min));
}

const isPropValuesEqual = (subject: any, target: any, propNames: any[]) =>
  propNames.every((propName) => subject[propName] === target[propName]);

export const getUniqueItemsByProperties = (items: any[], propNames: any[]) =>
  items.filter(
    (item, index, array) =>
      index ===
      array.findIndex((foundItem) =>
        isPropValuesEqual(foundItem, item, propNames)
      )
  );

export const storageUrl = (path?: string) => {
  if (!path) {
    return "";
  }

  return path;
};

export const hasPermission = ({
  userPermissions,
  requiredPermissions,
}: {
  userPermissions: Array<PermissionType>;
  requiredPermissions: Array<string> | null;
}) => {

  if (userPermissions.find((e) => e.slug === "all-permissions")) {
    return true;
  }

  if (!requiredPermissions || !userPermissions) {
    return false;
  }

  let userPermissionsSlugs = userPermissions.map((e) => {
    return e.slug;
  });

  let checker = requiredPermissions.some((v) =>
    userPermissionsSlugs.includes(v)
  );

  return checker;
};

export const getChangedValues = (values: any, initialValues: any) => {
  return Object.entries(values).reduce((acc: any, [key, value]: any) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const moveItemsInFront = ({
  findFunction,
  array,
}: {
  findFunction: (item: any) => {};
  array: any[];
}): any[] => [
  ...array.filter(findFunction),
  ...array.filter((item: any) => !findFunction(item)),
];

export const stringEclipse = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  }
  return str;
};

/**
 * Give path as a string and it will return an object
 * @param path - path to the file
 * @returns
 */
export const createFileFromPath = (path: string): FileType | null => {
  if (!path) {
    return null;
  }

  return {
    name: "",
    path: path,
    isUploaded: true,
  };
};

// is valid link
export const isValidLink = (link: string) => {
  const regex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return regex.test(link);
};
