import React from "react";
import { Box } from "@mui/material";
import { classNames } from "../../utils/classNames";

export default function FullLayout({
  children,
  className = "",
  style,
}: {
  children: any;
  className: string;
  style?: any;
}) {
  return (
    <Box
      sx={[
        {
          minHeight: "100vh",
          bgcolor: "background.paper",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        style,
      ]}
      className={classNames(["full-layout", className])}
    >
      {children}
    </Box>
  );
}
