import { Box } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { ReactElement } from "react";

function CustomToolbar({ rightComponent }: { rightComponent?: ReactElement }) {
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          marginBottom: 2,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: 2,
          flex: 1,
        }}
      >
        <Box>
          <GridToolbarDensitySelector sx={{ marginLeft: 1 }} />
          <GridToolbarColumnsButton sx={{ marginLeft: 1 }} />
          <GridToolbarFilterButton sx={{ marginLeft: 1 }} />
          <GridToolbarExport sx={{ marginLeft: 1 }} />
        </Box>

        {/* Test */}
        <Box>{rightComponent}</Box>
      </Box>
    </GridToolbarContainer>
  );
}

export default CustomToolbar;
