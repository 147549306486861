import { ReactNode, createContext, useState, useMemo, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage.hook";
import { PermissionType, UserType } from "../typescript/shared.types";
import { showToast } from "../utils/showToast";
import { customAxios } from "./app.context";

type UserContextInterface = {
  user: UserType | null;
  setUser: React.Dispatch<React.SetStateAction<UserType | null>>;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  logout: () => void;
  permissions: PermissionType[];
  tokenChecked: boolean;
};

const UserContext = createContext<UserContextInterface>({
  user: null,
  setUser: () => {},
  setToken: () => {},
  logout: () => {},
  permissions: [],
  tokenChecked: false,
});

type UserProviderProps = {
  children: ReactNode;
};

function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useLocalStorage<UserType | null>("user", null);
  const [token, setToken] = useLocalStorage<string>("token", "");
  const [permissions, setPermissions] = useState<PermissionType[]>([]);
  const [tokenChecked, setTokenChecked] = useState(false);
  useEffect(() => {
    if (user) {
      setPermissions(user.all_permissions || []);
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      customAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      customAxios.defaults.headers.common["Authorization"] = "";
    }
    setTokenChecked(true);
  }, [token]);

  // Custom  axios interceptors for 401
  useEffect(() => {
    const onResponseSuccess = (response: any) => {
      return response;
    };

    const onResponseError = (error: any) => {
      if (error.response.status === 401) {
        showToast({
          message: "Session expired. Please login again.",
          type: "error",
        });
        logout();
      }
      return Promise.reject(error);
    };

    customAxios.interceptors.response.use(onResponseSuccess, onResponseError);
  }, []);

  const logout = async () => {
    setUser(null);
    setToken("");
  };

  const store = {
    user,
    setUser,
    setToken,
    logout,
    permissions,
    tokenChecked,
  };

  const storeForProvider = useMemo(() => store, [store]);
  return (
    <UserContext.Provider value={storeForProvider}>
      {children}
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext };
