import LoginScreen from "../screen/login/login.screen";

import UsersScreen from "../screen/users/users.screen";
import RiddlesScreen from "../screen/riddles/riddles.screen";
import { RouteType } from "../typescript/types";
import RiddleScreen from "../screen/riddle/riddle.screen";
import UserScreen from "../screen/user/user.screen";
import AttemptsScreen from "../screen/users copy/attempts.screen";

// If protected you can't enter without user
// If redirectUser, you can't enter with user
export const routes: RouteType[] = [
  {
    path: "/",
    title: "Riddles",
    redirect: "/riddles",
    element: <RiddlesScreen />,
    protected: true,
  },
  {
    path: "/login",
    title: "Login",
    element: <LoginScreen />,
    protected: false,
    redirectUser: "/",
  },
  // Categories
  {
    title: "Riddles",
    path: "/riddles",
    element: <RiddlesScreen />,
    protected: true,
  },
  {
    title: "Riddle",
    path: "/riddle",
    element: <RiddleScreen />,
    protected: true,
  },
  {
    title: "Riddle",
    path: "/riddle/:slug",
    element: <RiddleScreen />,
    protected: true,
  },
  // Users
  {
    path: "/attempts",
    title: "Attempts",
    element: <AttemptsScreen />,
    protected: true,
    permissions: ["manage-users"],
  },
  {
    path: "/users",
    title: "Users",
    element: <UsersScreen />,
    protected: true,
    permissions: ["manage-users"],
  },
  {
    path: "/user/:id",
    title: "User",
    element: <UserScreen />,
    protected: true,
    permissions: ["manage-users"],
  },
  {
    path: "/user",
    title: "Utilizator nou",
    element: <UserScreen />,
    protected: true,
    permissions: ["manage-users"],
  },
  //   { path: "/about", exact: false, element: LoginScreen, protected: true },
];
