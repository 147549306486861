import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmModal from "../../components/modules/confirm-modal/confirm-modal.module";

import SidebarLayout from "../../layout/sidebar/sidebar.layout";

import { showToast } from "../../utils/showToast";
import FileUploader from "../../components/files-uploader/file-uploader.";
import LoadingScreen from "../loading-screen/loading.screen.";

import { FileType, UserType } from "../../typescript/shared.types";
import {
  deleteUserRequest,
  getUserByIdRequest,
  updateUserRequest,
} from "../../requests/user.requests";
import { createFileFromPath } from "../../utils/utils";
import { Input } from "../../components";
import { toast } from "react-toastify";
import moment from "moment";

export const CARD_STYLE = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  border: "1px solid #00000010",
  flex: 1,
  height: "100%",
  padding: 4,
};

const EmptyUser: UserType = {
  id: 0,
  firstName: "",
  username: "",
  lastName: "",
  birthdate: "",
  phone: "",
  phone_verified_at: "",
  apple_id: "",
  google_id: "",
  facebook_id: "",
  email: "",
  email_verified_at: "",
  avatar: "",
  created_at: "",
  updated_at: "",
  all_permissions: [],
};

export default function UserScreen() {
  const [user, setUser] = useState<UserType>(EmptyUser);
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [avatar, setAvatar] = useState<FileType | null>(null);

  const navigate = useNavigate();

  const updateUser = async () => {
    setIsLoading(true);

    const result = await updateUserRequest({
      ...user,
      // @ts-ignore
      avatar_id: avatar?.id || "",
    });

    setIsLoading(false);

    if (result.error) {
      return toast.error(result.message);
    }

    toast.success("Utilizator actualizat");
  };

  const fetchUser = async () => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    const result = await getUserByIdRequest({
      id: id,
    });

    setIsLoading(false);

    if (result.error) {
      return showToast({ type: "error", message: result.message });
    }

    if (result.users && result.users.length === 0) {
      return showToast({ type: "error", message: "User not found" });
    }

    const futureUser = result.user as UserType;
    setUser(futureUser);

    setAvatar(createFileFromPath(futureUser.avatar || ""));
  };

  useEffect(() => {
    setUser(EmptyUser);
    fetchUser();
  }, []);

  if (user.id === 0) {
    return <LoadingScreen />;
  }

  return (
    <SidebarLayout className="dashboard">
      <Box>
        <Typography
          sx={{ mb: -1, color: "text.primary", fontWeight: "700" }}
          variant="h5"
        >
          Edit User
        </Typography>
        <Typography
          sx={{ mb: 3, color: "text.secondary", fontWeight: "300" }}
          variant="subtitle1"
        >
          Please fill in the form below
        </Typography>
      </Box>

      <div
        style={{
          marginBottom: 16,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        {/* Left  */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: "380px",
            maxWidth: "380px",
          }}
        >
          <Box sx={{ ...CARD_STYLE, minWidth: "380px" }}>
            <FileUploader
              photoCategory="avatar"
              setFile={(e: FileType | null) => {
                setAvatar(e);
              }}
              file={avatar}
            />

            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <LoadingButton
                sx={{
                  flex: 1,
                  mr: 1,
                }}
                type="submit"
                variant="outlined"
                color="error"
                loading={isLoading}
                onClick={() => {
                  setDeleteModalOpen(true);
                }}
              >
                Delete
              </LoadingButton>
              <LoadingButton
                sx={{
                  flex: 1,
                  ml: 1,
                }}
                loading={isLoading}
                type="submit"
                variant="contained"
                color="primary"
                onClick={updateUser}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 2,
              ...CARD_STYLE,
              minWidth: "380px",
            }}
          >
            <Input
              fullWidth
              label="Username"
              name="username"
              disabled
              value={user?.username}
              onChange={(e) =>
                setUser({
                  ...user,
                  username: e.target.value.toString(),
                })
              }
              sx={{ mb: 3 }}
            />
            <Input
              fullWidth
              label="Email"
              name="email"
              disabled
              value={user?.email}
              sx={{ mb: 3 }}
            />
          </Box>
        </Box>

        {/* Right */}
        <Box
          sx={{
            ...CARD_STYLE,
          }}
        >
          {user?.guess_attempts?.length === 0 ? (
            <Box>
              <Typography
                sx={{ mb: -1, color: "text.primary", fontWeight: "300" }}
                variant="body1"
              >
                The user didn't try to guess any riddle
              </Typography>
            </Box>
          ) : null}
          {user?.guess_attempts?.reverse().map((attempt) => {
            return (
              <Box
                sx={{
                  flex: 1,
                  borderRadius: 2,
                  width: "100%",
                  border: (theme) =>
                    `1px solid ${
                      attempt.is_right_answer
                        ? theme.palette.primary.main
                        : theme.palette.divider
                    }`,
                  mb: 2,
                  p: 2,
                }}
              >
                <Typography
                  sx={{ mb: -1, color: "text.primary", fontWeight: "700" }}
                >
                  {/* @ts-ignore */}
                  {attempt.riddle?.title || attempt.id}
                </Typography>
                <Typography
                  sx={{ color: "text.secondary", fontWeight: "300" }}
                  variant="subtitle1"
                >
                  User tried to guess <b>{attempt.value}</b> and it {" "}
                  {attempt.is_right_answer ? "was" : "wasn't"} correct
                </Typography>
                {/* Date time */}
                <Typography
                  sx={{
                    color: "text.secondary",
                    fontWeight: "300",
                    fontSize: 12,
                  }}
                  variant="subtitle1"
                >
                  {/* time from now human friendly */}
                  {moment(attempt.created_at).fromNow()}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </div>

      {deleteModalOpen ? (
        <ConfirmModal
          title="Are you sure you want to delete this user?"
          onConfirm={async () => {
            setIsLoading(true);
            const result = await deleteUserRequest({
              id: user!.id,
            });
            setIsLoading(false);

            if (result.error) {
              return showToast({ type: "error", message: result.message });
            }

            navigate("/users");
            showToast({
              type: "success",
              message: "User deleted",
            });
          }}
          isLoading={isLoading}
          visible={deleteModalOpen}
          setVisible={setDeleteModalOpen}
        >
          <p>Deleted data can't be recovered</p>
        </ConfirmModal>
      ) : null}
    </SidebarLayout>
  );
}
