import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { DRAWER_WIDTH } from "../../constants/layout.constants";
import { UserContext } from "../../context/user.context";

// Icons
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

import { ThemeContext } from "../../context/theme.context";
import { useNavigate } from "react-router-dom";
import { getCurrentRouteTitle } from "../../utils/getRouteDetails";
import { logoutUserRequest } from "../../requests/user.requests";

export default function SidebarHeader({
  handleDrawerToggle,
  handleOpenUserMenu,
  handleCloseUserMenu,
  anchorElUser,
}: any) {
  const { mode, toggleTheme } = useContext(ThemeContext);

  const { user, logout } = useContext(UserContext);

  const navigate = useNavigate();
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundImage: "none",
        width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        ml: { sm: `${DRAWER_WIDTH}px` },
        bgcolor: "background.over",
        boxShadow: 24,
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="Open settings">
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0, color: "#000" }}
            >
              <Avatar alt={user?.firstName} src={user?.avatar} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Box sx={{ minWidth: 280 }}>
              <MenuItem
                onClick={() => {
                  navigate("/settings");
                }}
              >
                <Avatar />
                <Box>
                  <Typography
                    ml={2}
                    variant="body1"
                  >{`${user?.firstName} ${user?.lastName}`}</Typography>
                </Box>
              </MenuItem>

              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
              <MenuItem
                onClick={() => {
                  toggleTheme();
                }}
              >
                {mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
                <Typography sx={{ fontSize: 14 }} ml={2} textAlign="center">
                  Theme
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  await logoutUserRequest();
                  logout();
                }}
              >
                <ExitToAppIcon />
                <Typography ml={2} sx={{ fontSize: 14 }} textAlign="center">
                  Logout
                </Typography>
              </MenuItem>
            </Box>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
