import React from "react";
import { useContext } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import DEPLOY_DIRECTORY from "../constants/app.constants";
import { UserContext } from "../context/user.context";
import Page404Screen from "../screen/404/404.screen";
import { hasPermission } from "../utils/utils";
import { routes } from "./routes.navigation";
import { RouteType } from "../typescript/types";
import ImageViewer from "../components/modules/image-viewer/image-viewer.module";
import PdfViewer from "../components/modules/pdf-viewer/pdf-viewer.module";

function RouteLogic({ route }: { route: RouteType }) {
  const { permissions, user } = useContext(UserContext);
  let location = useLocation();

  if (route.redirect) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={route.redirect} state={{ from: location }} replace />;
  }
  if (!user && route.protected) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (
    route.permissions &&
    !hasPermission({
      userPermissions: permissions,
      requiredPermissions: route.permissions,
    })
  ) {
    return (
      <Navigate
        to={route.redirectUser || "/"}
        state={{ from: location }}
        replace
      />
    );
  }

  if (user && route.redirectUser) {
    // Redirect user to
    return (
      <Navigate to={route.redirectUser} state={{ from: location }} replace />
    );
  }

  return route.element;
}

export default function MainNavigation() {
  const { tokenChecked } = useContext(UserContext);

  if(!tokenChecked){
    return <>Loading</>
  }

  return (
    <BrowserRouter basename={DEPLOY_DIRECTORY}>
      <Routes>
        {routes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<RouteLogic route={route}></RouteLogic>}
            />
          );
        })}

        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        {/* 404 */}
        <Route path="*" element={<Page404Screen />} />
      </Routes>

      <ImageViewer />
      <PdfViewer />
    </BrowserRouter>
  );
}
