import { TextField, TextFieldProps } from "@mui/material";
import React from "react";

export default function Input(props: TextFieldProps) {
  return (
    <TextField
      {...props}
    />
  );
}
