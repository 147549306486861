import { Box, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { storageUrl } from "../../utils/utils";
import { FileType } from "../../typescript/shared.types";
const UploadedImage = ({
  image,
  onDelete,
  onImageClick,
  wrapperStyle,
  imageStyle,
}: {
  image: FileType;
  onDelete: any;
  onImageClick?: any;
  wrapperStyle?: any;
  imageStyle?: any;
}) => {
  return (
    <Box
      sx={{
        opacity: image.isUploaded || storageUrl(image.path) ? 1 : 0.2,
        transition: "0.2s",
        position: "relative",
        width: "30%",
        height: 80,
        margin: 1,
        bgcolor: "background.darker",
        ...wrapperStyle,
      }}
    >
      <IconButton
        onClick={() => {
          onDelete(image);
        }}
        color="error"
        aria-label="delete picture"
        component="span"
        sx={{ position: "absolute", top: -16, right: -16, zIndex: 10 }}
      >
        <CancelIcon
          sx={{ bgcolor: "background.subtil", borderRadius: "50%" }}
        />
      </IconButton>

      <img
        className={Boolean(onImageClick) ? "clickable" : ""}
        onClick={onImageClick}
        style={{
          width: "100%",
          height: 80,
          objectFit: "contain",
          position: "absolute",
          zIndex: 9,
          ...imageStyle,
        }}
        alt="preview"
        src={image.preview || storageUrl(image.path)}
      />
    </Box>
  );
};

export default UploadedImage;
