import { customAxios } from "../context/app.context";
import { ApiResponse } from "../typescript/shared.types";

export const uploadFile = async ({
  file,
  category,
}: {
  category: string;
  file: any;
}): Promise<ApiResponse> => {
  var formData = new FormData();
  formData.append("file", file);
  formData.append("type", "public");
  formData.append("category", category);

  const response = await customAxios.post("/file/upload", formData);

  return response.data;
};

// get vouchers
export const getVouchersRequest = async (): Promise<ApiResponse> => {
  const response = await customAxios.post("/voucher/get");
  return response.data;
};

// delete voucher
export const deleteVoucherRequest = async (payload: {
  voucher_id: number;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/voucher/delete", payload);
  return response.data;
};

// create voucher request
export const createVoucherRequest = async (payload: {
  code: string;
  credits: number;
  uses_left: number;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/voucher/create", payload);
  return response.data;
};
