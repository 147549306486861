import AppProvider from "./context/app.context";
import ThemeProvider from "./context/theme.context";
import { UserProvider } from "./context/user.context";
import MainNavigation from "./navigation/main.navigation";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-loading-skeleton/dist/skeleton.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <AppProvider>
        <ToastContainer />
        <ThemeProvider>
          <UserProvider>
            <MainNavigation />
          </UserProvider>
        </ThemeProvider>
      </AppProvider>
    </LocalizationProvider>
  );
}

export default App;
