import { Box, SxProps, Theme, Toolbar } from "@mui/material";
import React from "react";
import { classNames } from "../../utils/classNames";
import SidebarHeader from "./header.sidebar";
import { DRAWER_WIDTH } from "../../constants/layout.constants";
import SidebarDrawer from "./drawer.sidebar";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "@mui/system";

export default function SidebarLayout({
  children,
  className = "",
  style,
  wrapperStyle,
  isLoading = false,
}: {
  children: any;
  className: string;
  style?: Object;
  wrapperStyle?: SxProps<Theme>;
  isLoading?: boolean;
}) {
  const [anchorElUser, setAnchorElUser] = React.useState<any>(null);
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box
      sx={{ display: "flex", background: theme.palette.background.default }}
      className={classNames(["sidebar-layout", className])}
    >
      {/* Header */}
      <SidebarHeader
        handleDrawerToggle={handleDrawerToggle}
        handleOpenUserMenu={handleOpenUserMenu}
        handleCloseUserMenu={handleCloseUserMenu}
        anchorElUser={anchorElUser}
      />
      <SidebarDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          bgcolor: "background.default",
          maxWidth: "1200px",
          margin: "0 auto",
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        }}
      >
        <Toolbar />

        {isLoading ? (
          <Skeleton
            style={{ minHeight: "calc(100vh - 104px)" }}
            highlightColor={theme.palette.background.paper}
            baseColor={theme.palette.background.default}
          />
        ) : (
          <Box
            sx={{
              borderRadius: 1,
              p: 3,
              minHeight: "calc(100vh - 104px)",
              ...wrapperStyle,
            }}
          >
            {children}
          </Box>
        )}
      </Box>
    </Box>
  );
}
