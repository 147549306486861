import React, { useContext, useState } from "react";
import Lightbox from "react-image-lightbox";
import { AppContext } from "../../../context/app.context";


export default function ImageViewer() {
  const [step, setStep] = useState<number>(0);
  const {images, setImages} = useContext<any>(AppContext);

  if (!images) {
    return <></>;
  }

  return (
    <Lightbox
      reactModalStyle={{
        overlay: { zIndex: 99999 },
      }}
      mainSrc={images[step]}
      nextSrc={images[step + 1]}
      prevSrc={images[step - 1]}
      onCloseRequest={() => {
        setImages(null);
      }}
      onMovePrevRequest={() => {
        setStep(step - 1);
      }}
      onMoveNextRequest={() => {
        setStep(step + 1);
      }}
    />
  );
}
