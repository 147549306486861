import { Avatar, Box, Chip, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import SidebarLayout from "../../layout/sidebar/sidebar.layout";
import {
  DataGrid,
  GridRenderCellParams,
  GridValueGetterParams,
  GridColumns,
} from "@mui/x-data-grid";

import { showToast } from "../../utils/showToast";
import {
  getAttemptsRequest,
  getUsersRequest,
} from "../../requests/users.requests";
import { storageUrl } from "../../utils/utils";
import { AppContext } from "../../context/app.context";
import { GuessAttempt, UserType } from "../../typescript/shared.types";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CustomToolbar } from "../../components";
import useLocalStorage from "../../hooks/useLocalStorage.hook";

export default function AttemptsScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<UserType[]>([]);
  const { setImages } = useContext(AppContext);
  const navigation = useNavigate();
  const [filters, setFilter] = useLocalStorage<any>("attemps_filters", null);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);

  const loadData = async () => {
    setIsLoading(true);
    const result = await getAttemptsRequest();
    setIsLoading(false);
    if (result.error) {
      showToast({ type: "error", message: result.message });
    }

    setTotal(result.attempts.length);
    setData(result.attempts);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  // Columns
  const columns = useMemo(() => {
    return generateColumns({ setImages, navigation: navigation });
  }, [setImages]);

  return (
    <SidebarLayout className="dashboard">
      <Typography
        sx={{ mb: -1, color: "text.primary", fontWeight: "700" }}
        variant="h5"
      >
        Attempts
      </Typography>
      <Typography
        sx={{ mb: 3, color: "text.secondary", fontWeight: "300" }}
        variant="subtitle1"
      >
        List of all attempts
      </Typography>

      <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
        <DataGrid
          filterModel={filters || { items: [] }}
          onFilterModelChange={(model) => {
            setFilter(model);
          }}
          components={{
            Toolbar: () => <CustomToolbar />,
          }}
          loading={isLoading}
          isRowSelectable={() => false}
          rows={data}
          sx={{
            backgroundColor: "background.paper",
          }}
          columns={columns}
          pageSize={perPage}
          rowsPerPageOptions={[25, 50, 100]}
          rowCount={total}
        />
      </div>
    </SidebarLayout>
  );
}

const generateColumns = ({
  setImages,
  navigation,
}: {
  setImages: any;
  navigation: any;
}): GridColumns => {
  return [
    {
      field: "id",
      type: "number",
      headerName: "ID",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      type: "string",
      headerName: "Value",
      width: 220,
      headerAlign: "center",
      align: "center",
    },
    {
      headerName: "User",
      field: "user_id",
      sortable: true,
      width: 160,
      renderCell: (params: GridRenderCellParams) => {
        const attempt = params.row as GuessAttempt;

        return (
          <Typography
            className="clickable"
            variant="body2"
            sx={{
              color: "primary.main",
              textDecoration: "underline",
            }}
            onClick={() => {
              navigation("/user/" + attempt.user_id);
            }}
          >
            {attempt.user_id}
          </Typography>
        );
      },
    },
    {
      headerName: "Riddle",
      field: "riddle_id",
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const attempt = params.row as GuessAttempt;
        return (
          <Typography
            className="clickable"
            variant="body2"
            sx={{
              color: "primary.main",
              textDecoration: "underline",
            }}
            onClick={() => {
              navigation("/riddle/" + attempt.riddle_id);
            }}
          >
            {attempt.riddle_id}
          </Typography>
        );
      },
    },

    {
      field: "guess_attemps",
      headerName: "Was correct",
      type: "boolean",
      sortable: true,
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        const attempt = params.row as GuessAttempt;
        return attempt.is_right_answer;
      },
    },

    // createdAt
    {
      field: "created_at",
      headerName: "Created At",
      type: "dateTime",
      sortable: true,
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        const attempt = params.row as GuessAttempt;
        return moment(attempt.created_at).format("DD/MM/YYYY HH:mm");
      },
    },
  ];
};
