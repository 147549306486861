import { DateTimePicker, LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Input } from "../../../components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { showToast } from "../../../utils/showToast";
import {
  AlmostWord,
  RiddleType,
  UserType,
} from "../../../typescript/shared.types";
import React from "react";
import {
  addOrUpdateAlmostWordRequest,
  bulkAddAlmostWordsRequest,
  deleteAlmostWordRequest,
} from "../../../requests/riddle.requests";
import ConfirmModal from "../../../components/modules/confirm-modal/confirm-modal.module";

const AlmosWord = ({
  almostWord,
  onEdit,
  onDelete,
}: {
  almostWord: AlmostWord;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const [deleteClicked, setDeleteClicked] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <>
      <ConfirmModal
        title="Are you sure?"
        onConfirm={async () => {
          setIsLoading(true);
          await onDelete();
          setIsLoading(false);
        }}
        isLoading={isLoading}
        visible={deleteClicked}
        setVisible={setDeleteClicked}
      >
        <p>
          You are about to delete the almost word <b>{almostWord?.value}</b>
        </p>
      </ConfirmModal>
      <Box
        sx={{
          m: 1,
          padding: "4px 8px",
          bgcolor: "background.darker",
          borderRadius: 2,
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          minWidth: 200,
          width: "calc(100% / 4 - 16px)",
        }}
      >
        <Box>
          <Typography
            typography={"body2"}
            sx={{
              color: "text.primary",
            }}
          >
            {almostWord.value}
          </Typography>
          <Typography
            typography={"caption"}
            sx={{
              opacity: 0.7,
              color: "text.primary",
            }}
          >
            {almostWord.seens}
          </Typography>
        </Box>

        <Box
          sx={{
            borderRadius: 33,
            ml: 2,
            color: "text.secondary",
          }}
        >
          <EditIcon
            sx={{
              fontSize: 20,
            }}
            className="clickable"
            onClick={() => {
              onEdit();
            }}
          />
          <DeleteIcon
            sx={{
              fontSize: 20,
              color: "error.main",
            }}
            className="clickable"
            onClick={() => {
              setDeleteClicked(true);
            }}
          />
        </Box>
      </Box>
    </>
  );
};

const RiddleAlmostWords = ({
  riddle,
  setRiddle,
}: {
  riddle: RiddleType;
  setRiddle: Dispatch<SetStateAction<RiddleType | null>>;
}) => {
  const theme = useTheme();
  const isfullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedAlmostWord, setSelectedAlmostWord] =
    useState<AlmostWord | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [seens, setSeens] = useState<string>("0");

  useEffect(() => {
    if (selectedAlmostWord) {
      setValue(selectedAlmostWord.value);
      setSeens(selectedAlmostWord.seens.toString());
    } else {
      setValue("");
      setSeens("0");
    }
  }, [selectedAlmostWord]);

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [bulkAlmostWords, setBulkAlmostWords] = useState<string>("");
  const [bulkModalVisible, setBulkModalVisible] = useState<boolean>(false);
  if (!riddle || !riddle?.almost_words) {
    return null;
  }

  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        bgcolor: "background.paper",
        flex: 1,
        height: "100%",
        padding: 4,
        boxShadow: 24,
      }}
    >
      <Typography
        sx={{ mb: -1, color: "text.primary", fontWeight: "700" }}
        variant="h5"
      >
        Almost Words
      </Typography>
      <Typography
        sx={{ mb: 3, color: "text.secondary", fontWeight: "300" }}
        variant="subtitle1"
      >
        All almost words
      </Typography>

      <Grid container spacing={2}>
        {riddle?.almost_words.map((almostWord) => (
          <AlmosWord
            onDelete={async () => {
              setIsLoading(true);
              const response = await deleteAlmostWordRequest({
                id: almostWord.id,
              });
              setIsLoading(false);

              if (response.error) {
                showToast({ message: response.message, type: "error" });
                return;
              }

              setRiddle((prev) => {
                if (prev) {
                  return {
                    ...prev,
                    almost_words: prev.almost_words.filter(
                      (c) => c.id !== almostWord.id
                    ),
                  };
                }
                return prev;
              });

              showToast({ message: "Almost Word deleted" });
            }}
            key={almostWord.id}
            onEdit={() => {
              setSelectedAlmostWord(almostWord);
              setModalVisible(true);
            }}
            almostWord={almostWord}
          />
        ))}

        <Box
          sx={{
            m: 1,
            p: 2,
            bgcolor: "primary.main",
            borderRadius: 2,
            position: "relative",
          }}
          className="clickable"
          onClick={() => {
            setSelectedAlmostWord(null);
            setModalVisible(true);
          }}
        >
          <Typography
            sx={{
              color: "#fff",
            }}
          >
            Add almost word
          </Typography>
        </Box>
        <Box
          sx={{
            m: 1,
            p: 2,
            bgcolor: "primary.main",
            borderRadius: 2,
            position: "relative",
          }}
          className="clickable"
          onClick={() => {
            setBulkModalVisible(true);
          }}
        >
          <Typography
            sx={{
              color: "#fff",
            }}
          >
            Add bulk almost words
          </Typography>
        </Box>
      </Grid>

      {modalVisible ? (
        <Dialog
          fullScreen={isfullScreen}
          open={modalVisible}
          onClose={() => {
            setSelectedAlmostWord(null);
            setModalVisible(false);
          }}
          closeAfterTransition
        >
          <Box
            sx={{
              minWidth: "360px",
            }}
          >
            <DialogTitle>
              {selectedAlmostWord ? "Edit almostWord" : "Add almostWord"}
            </DialogTitle>
            <DialogContent>
              {/* User */}

              <Input
                fullWidth
                sx={{
                  mt: 2,
                }}
                label="Value"
                value={value}
                onChange={(ev) => {
                  setValue(ev.target.value);
                }}
                placeholder="AlmostWord 1"
                error={!value}
                helperText={!value ? "Value is required" : ""}
              />

              <Input
                fullWidth
                sx={{
                  mt: 2,
                }}
                disabled
                label="Seens"
                value={seens}
                placeholder=""
              />

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                }}
              >
                <LoadingButton
                  size="large"
                  sx={{
                    flex: 1,
                  }}
                  disabled={!value}
                  variant="contained"
                  loading={isLoading}
                  onClick={async () => {
                    // update
                    setIsLoading(true);
                    const response = await addOrUpdateAlmostWordRequest({
                      // @ts-ignore
                      id: selectedAlmostWord?.id || null,
                      // @ts-ignore
                      value,
                      riddle_id: riddle.id,
                    });

                    setIsLoading(false);

                    if (response.error) {
                      showToast({ message: response.message, type: "error" });
                      return;
                    }

                    setRiddle((prev) => {
                      if (prev) {
                        return {
                          ...prev,
                          almost_words: [
                            ...prev.almost_words.filter(
                              (c) => c.id !== response.almost_word.id
                            ),
                            response.almost_word,
                          ],
                        };
                      }
                      return prev;
                    });

                    setValue("");
                    setSeens("0");

                    setModalVisible(false);

                    showToast({ message: "AlmostWord saved" });
                  }}
                >
                  Save
                </LoadingButton>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      ) : null}

      {bulkModalVisible ? (
        <Dialog
          fullScreen={isfullScreen}
          open={bulkModalVisible}
          onClose={() => {
            setBulkModalVisible(false);
          }}
          closeAfterTransition
        >
          <Box
            sx={{
              minWidth: "360px",
            }}
          >
            <DialogTitle>Bulk add almost words</DialogTitle>
            <DialogContent>
              {/* User */}

              <Input
                fullWidth
                sx={{
                  mt: 2,
                }}
                label="Values"
                value={bulkAlmostWords}
                onChange={(ev) => {
                  setBulkAlmostWords(ev.target.value);
                }}
                placeholder="Word1, Word2, Word3"
              />

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                }}
              >
                <LoadingButton
                  size="large"
                  sx={{
                    flex: 1,
                  }}
                  disabled={!bulkAlmostWords}
                  variant="contained"
                  loading={isLoading}
                  onClick={async () => {
                    // update
                    setIsLoading(true);

                    const futureWords = bulkAlmostWords.split(",");

                    // remove empty spaces
                    const almostWords = futureWords.map((w) => w.trim());

                    // remove duplicates
                    const uniqueAlmostWords = [...new Set(almostWords)];

                    // remove empty strings
                    const finalBulkAlmostWords = uniqueAlmostWords.filter(
                      (w) => w
                    );

                    const response = await bulkAddAlmostWordsRequest({
                      // @ts-ignore
                      value: finalBulkAlmostWords.join(","),
                      riddle_id: riddle.id,
                    });

                    setIsLoading(false);

                    if (response.error) {
                      showToast({ message: response.message, type: "error" });
                      return;
                    }

                    setRiddle((prev) => {
                      if (prev) {
                        return {
                          ...prev,
                          almost_words: response.almost_words,
                        };
                      }
                      return prev;
                    });

                    setBulkAlmostWords("");
                    setBulkModalVisible(false);
                    showToast({ message: "AlmostWord saved" });
                  }}
                >
                  Save
                </LoadingButton>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      ) : null}
    </Box>
  );
};

function uuid(mask = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx") {
  return `${mask}`.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default RiddleAlmostWords;
