
import { toast, ToastPosition } from "react-toastify";
export const showToast = ({
  type,
  message,
  position = "bottom-right",
}: {
  type?: string;
  message: string;
  position?: ToastPosition;
}) => {
  const themeMode = window.localStorage["theme"]
    ? JSON.parse(window.localStorage["theme"])
    : "";

  const config = {
    position,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: themeMode,
  };

  if (!type) {
    toast(message, config);
  }

  if (type === "error") {
    toast.error(message, config);
  }
  if (type === "info") {
    toast.info(message, config);
  }
  if (type === "success") {
    toast.success(message, config);
  }
  if (type === "warning") {
    toast.warn(message, config);
  }
};
