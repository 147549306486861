import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import Dropzone from "react-dropzone";
import { AppContext } from "../../context/app.context";
import { showToast } from "../../utils/showToast";
import { v4 as uuid } from "uuid";

import { storageUrl } from "../../utils/utils";
import { useTheme } from "@mui/system";
import { uploadFile } from "../../requests/general.requests";
import UploadedImage from "./uploaded-image";
import { FileType, SetValue } from "../../typescript/shared.types";

export default function FileUploader({
  label,
  file,
  setFile,
  style,
  setIsLoading = (p: boolean) => {},
  accept = "image/*",
  photoCategory,
}: {
  label?: string;
  file: FileType | null;
  setFile: SetValue<FileType | null>;
  setIsLoading?: any;
  style?: any;
  accept?: string;
  photoCategory: string;
}) {
  const { setImages } = useContext(AppContext);
  const theme = useTheme();

  const onDrop = async (newFiles: any) => {
    // New file
    let newFile = newFiles[0];
    let futureName = newFile.name.split(".");

    // Rename
    futureName = futureName[0].replace(" ", "") + uuid() + "." + futureName[1];

    newFile = new File([newFile], futureName, {
      type: newFile.type,
    });

    newFile.preview = URL.createObjectURL(newFile);
    newFile.isUploaded = false;

    setIsLoading(true);
    uploadFile({
      file: newFile,
      category: photoCategory,
    }).then((response: any) => {
      // Error

      if (response.error) {
        setFile(null);

        setIsLoading(false);
        return showToast({ type: "error", message: response.message });
      }

      const file = response.file;
      // Success

      if (!file) {
        setFile(null);
      }

      const futureFile = JSON.parse(JSON.stringify(file)) as FileType;

      futureFile.isUploaded = true;
      futureFile.path = file.url;

      setFile(futureFile);
      setIsLoading(false);
    });

    setFile(newFile);
  };

  const getBorderColor = ({
    isDragAccept,
    isDragReject,
  }: {
    isDragAccept: boolean;
    isDragReject: boolean;
  }): object => {
    if (isDragReject) {
      return { outline: "2px dashed #d32f2f", border: "1px solid transparent" };
    }
    if (isDragAccept) {
      return { outline: "2px dashed #5db11e", border: "1px solid transparent" };
    }

    return { border: `1px dashed  ${theme.palette.border.input}` };
  };

  return (
    <Dropzone accept={[accept]} onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
        <Box
          style={style}
          sx={[
            {
              width: "200px",
              height: "200px",
              display: "flex",
              borderRadius: 2,
              justifyContent: "center",
              alignItems: "center",
              p: 0,
              position: "relative",
              border: `1px dashed rgb(0,0,0,0.23)`,
            },
            getBorderColor({
              isDragAccept,
              isDragReject,
            }),
          ]}
        >
          {label ? (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: 99,
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  textAlign: "center",
                  color: "text.primary",
                }}
              >
                {label}
              </Typography>
            </Box>
          ) : null}
          {/* Preview */}

          {file ? (
            <UploadedImage
              wrapperStyle={{
                width: "100%",
                height: "100%",
                margin: 0,
              }}
              imageStyle={{
                width: "100%",
                height: "100%",
              }}
              onImageClick={() => {
                setImages([storageUrl(file.path)]);
              }}
              onDelete={(image: FileType) => {
                setFile(null);
              }}
              image={file}
            />
          ) : (
            <Box
              {...getRootProps({ className: "dropzone clickable" })}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input {...getInputProps()} />
              <Typography
                sx={{
                  textAlign: "center",
                  color: "text.primary",
                }}
              >
                <b style={{ borderBottom: "1px solid blue" }}>Pick file</b>
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Dropzone>
  );
}
