import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import SidebarLayout from "../../layout/sidebar/sidebar.layout";
import {
  DataGrid,
  GridRenderCellParams,
  GridValueGetterParams,
  GridColumns,
  GridToolbar,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../utils/showToast";
import { CustomToolbar } from "../../components";
import { storageUrl } from "../../utils/utils";
import { AppContext } from "../../context/app.context";
import { RiddleType, UserType } from "../../typescript/shared.types";
import { getAllRiddlesRequest } from "../../requests/riddle.requests";
import useLocalStorage from "../../hooks/useLocalStorage.hook";

export default function RiddlesScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilter] = useLocalStorage<any>("riddle_filters", null);

  const navigation = useNavigate();
  const [data, setData] = useState<UserType[]>([]);
  const { setImages } = useContext(AppContext);
  const getRiddles = async () => {
    setIsLoading(true);
    const result = await getAllRiddlesRequest();
    setIsLoading(false);
    if (result.error) {
      showToast({ type: "error", message: result.message });
    }
    setData(result.riddles);
  };

  useEffect(() => {
    getRiddles();
    // eslint-disable-next-line
  }, []);

  // Columns
  const columns = useMemo(() => {
    return generateColumns({ setImages, navigation });
  }, [setImages]);

  return (
    <SidebarLayout className="dashboard">
      <Typography
        sx={{ mb: -1, color: "text.primary", fontWeight: "700" }}
        variant="h5"
      >
        Riddles
      </Typography>
      <Typography
        sx={{ mb: 3, color: "text.secondary", fontWeight: "300" }}
        variant="subtitle1"
      >
        List of all riddles
      </Typography>

      <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
        <DataGrid
          filterModel={filters || { items: [] }}
          onFilterModelChange={(model) => {
            setFilter(model);
          }}
          loading={isLoading}
          isRowSelectable={() => false}
          rows={data}
          columns={columns}
          sx={{
            backgroundColor: "background.paper",
          }}
          pageSize={100}
          rowsPerPageOptions={[100]}
          components={{
            Toolbar: () => (
              <CustomToolbar
                rightComponent={
                  <div>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigation("/riddle");
                      }}
                    >
                      Add riddle
                    </Button>
                  </div>
                }
              />
            ),
          }}
        />
      </div>
    </SidebarLayout>
  );
}

const generateColumns = ({
  setImages,
  navigation,
}: {
  setImages: any;
  navigation: any;
}): GridColumns => {
  return [
    {
      field: "id",
      type: "number",
      headerName: "ID",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "question",
      type: "string",
      headerName: "Question",
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            className="clickable"
            variant="body2"
            sx={{
              color: "primary.main",
              textDecoration: "underline",
            }}
            onClick={() => {
              navigation("/riddle/" + params.row.slug);
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },

    {
      headerName: "Answer",
      field: "answer",
      sortable: true,
      width: 160,
    },

    {
      headerName: "Status",
      field: "status",
      sortable: true,
      width: 160,
    },

    {
      headerName: "Active after",
      field: "active_after_date",
      type: "date",
      sortable: true,
      width: 160,
    },
  ];
};
