import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ReactComponent as WarningSvg } from "../../../assets/svg/warning.svg";

import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SetValue } from "../../../typescript/shared.types";
export default function ConfirmModal({
  visible,
  setVisible,
  title,
  isLoading,
  onConfirm,
  confirmText = "Yes",
  cancelText = "No",
  children,
}: {
  visible: boolean;
  setVisible: SetValue<boolean>;
  title: string;
  isLoading?: boolean;
  onConfirm: any;
  confirmText?: string;
  cancelText?: string;
  children: any;
}) {
  return (
    <Dialog
      open={visible}
      onClose={() => {
        setVisible(false);
      }}
      PaperProps={{
        style: {
          borderRadius: "1rem",
        },
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            onClick={() => {
              setVisible(false);
            }}
            aria-label="delete"
            size="large"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        {/* Lottie */}
        <Box
          sx={{ marginBottom: 2, justifyContent: "center", display: "flex" }}
        >
          <WarningSvg />
        </Box>

        <DialogTitle
          sx={{
            textAlign: "center",
            maxWidth: "350px",
            margin: "0 auto",
            lineHeight: "24px",
          }}
          id="alert-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ textAlign: "center", maxWidth: "370px", margin: "0 auto" }}
            id="alert-dialog-description"
          >
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            paddingBottom: 4,
            paddingTop: 4,
          }}
        >
          <Button
            size="large"
            sx={{
              minWidth: "200px",
            }}
            onClick={() => {
              setVisible(false);
            }}
          >
            {cancelText}
          </Button>
          <LoadingButton
            size="large"
            sx={{ minWidth: "200px", boxShadow: 0 }}
            variant="contained"
            loading={isLoading}
            onClick={() => {
              onConfirm();
            }}
            autoFocus
          >
            {confirmText}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
