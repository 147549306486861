import React, { createContext, useEffect, useMemo } from "react";
import useLocalStorage from "../hooks/useLocalStorage.hook";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { useMediaQuery, responsiveFontSizes, PaletteMode } from "@mui/material";

const ThemeContext = createContext<any>(null);

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    cancel: true;
  }
}

function ThemeProvider(props: any) {
  // Learn about mui style
  // https://mui.com/system/the-sx-prop/
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [mode, setMode] = useLocalStorage<PaletteMode>(
    "theme",
    prefersDarkMode ? "dark" : "light"
  );

  const isDarkTheme = (): boolean => {
    return mode === "dark";
  };

  const theme = responsiveFontSizes(
    React.useMemo(
      () =>
        createTheme({
          shape: {
            borderRadius: 4,
          },
          palette: {
            mode,
            divider: isDarkTheme()
              ? "rgba(255, 255, 255, 0.05)"
              : "rgba(0, 0, 0, 0.05)",
            background: {
              paper: isDarkTheme() ? "#161616" : "#fff",
              // @ts-expect-error
              over: isDarkTheme() ? "#0e0e0e" : "#fff",
              subtil: isDarkTheme() ? "#1b1b1b" : "#f9f9f9",
              darker: isDarkTheme() ? "#101010" : "#eee",
            },
            border: {
              input: isDarkTheme()
                ? "rgba(255, 255, 255, 0.23)"
                : "rgba(0, 0, 0, 0.23)",
            },
            primary: {
              contrastText: "#fff",
              dark: "#7e2f83",
              light: "##c643ce",
              main: "#a739ae",
            },
          },
          typography: {
            fontFamily: "Poppins, sans-serif",
          },
          shadows: [
            "none",
            "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.08),0px 1px 3px 0px rgba(0,0,0,0.08)",
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.08)",
            "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.08),0px 1px 8px 0px rgba(0,0,0,0.08)",
            "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.08),0px 1px 10px 0px rgba(0,0,0,0.08)",
            "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.08),0px 1px 14px 0px rgba(0,0,0,0.08)",
            "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.08),0px 1px 18px 0px rgba(0,0,0,0.08)",
            "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.08),0px 2px 16px 1px rgba(0,0,0,0.08)",
            "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.08),0px 3px 14px 2px rgba(0,0,0,0.08)",
            "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 08px 1px rgba(0,0,0,0.08),0px 3px 16px 2px rgba(0,0,0,0.08)",
            "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.08),0px 4px 18px 3px rgba(0,0,0,0.08)",
            "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.08),0px 4px 20px 3px rgba(0,0,0,0.08)",
            "0px 7px 8px -4px rgba(0,0,0,0.2),0px 08px 17px 2px rgba(0,0,0,0.08),0px 5px 22px 4px rgba(0,0,0,0.08)",
            "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.08),0px 5px 24px 4px rgba(0,0,0,0.08)",
            "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.08),0px 5px 26px 4px rgba(0,0,0,0.08)",
            "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.08),0px 6px 28px 5px rgba(0,0,0,0.08)",
            "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.08),0px 6px 30px 5px rgba(0,0,0,0.08)",
            "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.08),0px 6px 32px 5px rgba(0,0,0,0.08)",
            "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.08),0px 7px 34px 6px rgba(0,0,0,0.08)",
            "0px 9px 08px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.08),0px 7px 36px 6px rgba(0,0,0,0.08)",
            "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.08),0px 8px 38px 7px rgba(0,0,0,0.08)",
            "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.08),0px 8px 40px 7px rgba(0,0,0,0.08)",
            "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.08),0px 8px 42px 7px rgba(0,0,0,0.08)",
            "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.08),0px 9px 44px 8px rgba(0,0,0,0.08)",
            "0 5px 70px -20px #00000029",
          ],
        }),
      // eslint-disable-next-line
      [mode]
    )
  );

  useEffect(() => {
    console.log(`${mode} theme => `, theme);
    // eslint-disable-next-line
  }, [mode]);

  const toggleTheme = () => {
    if (mode === "dark") {
      setMode("light");
    } else {
      setMode("dark");
    }
  };

  // eslint-disable-next-line
  const store = {
    toggleTheme,
    mode,
  };

  const storeForProvider = useMemo(() => store, [store]);
  return (
    <ThemeContext.Provider value={storeForProvider}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export { ThemeContext };
export default ThemeProvider;
