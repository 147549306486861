import { DatePicker, DateTimePicker, LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Input } from "../../../components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { showToast } from "../../../utils/showToast";
import { isValidLink } from "../../../utils/utils";
import {
  ClueType,
  RiddleType,
  UserType,
} from "../../../typescript/shared.types";
import React from "react";
import {
  addOrUpdateClueRequest,
  deleteClueRequest,
} from "../../../requests/riddle.requests";
import ConfirmModal from "../../../components/modules/confirm-modal/confirm-modal.module";
import moment from "moment";

const Clue = ({
  clue,
  onEdit,
  onDelete,
}: {
  clue: ClueType;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const [deleteClicked, setDeleteClicked] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <>
      <ConfirmModal
        title="Are you sure?"
        onConfirm={async () => {
          setIsLoading(true);
          await onDelete();
          setIsLoading(false);
        }}
        isLoading={isLoading}
        visible={deleteClicked}
        setVisible={setDeleteClicked}
      >
        <p>
          You are about to delete the riddle <b>{clue?.value}</b>
        </p>
      </ConfirmModal>
      <Box
        sx={{
          m: 1,
          padding: "4px 8px",
          bgcolor: "background.darker",
          borderRadius: 2,
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          minWidth: 200,
          width: "calc(100% / 4 - 16px)",
        }}
      >
        <Box>
          <Typography
            typography={"body2"}
            sx={{
              color: "text.primary",
            }}
          >
            {clue.value}
          </Typography>
          <Typography
            typography={"caption"}
            sx={{
              opacity: 0.7,
              color: "text.primary",
            }}
          >
            {clue.public_after}
          </Typography>
        </Box>

        <Box
          sx={{
            borderRadius: 33,
            ml: 2,
            color: "text.secondary",
          }}
        >
          <EditIcon
            sx={{
              fontSize: 20,
            }}
            className="clickable"
            onClick={() => {
              onEdit();
            }}
          />
          <DeleteIcon
            sx={{
              fontSize: 20,
              color: "error.main",
            }}
            className="clickable"
            onClick={() => {
              setDeleteClicked(true);
            }}
          />
        </Box>
      </Box>
    </>
  );
};

const RiddleClues = ({
  riddle,
  setRiddle,
}: {
  riddle: RiddleType;
  setRiddle: Dispatch<SetStateAction<RiddleType | null>>;
}) => {
  const theme = useTheme();
  const isfullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedClue, setSelectedClue] = useState<ClueType | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [publicAfter, setPublicAfter] = useState<string>("");
  const [hasBeenResolvedBy, setHasBeenResolvedBy] = useState<UserType | null>(
    null
  );
  const [triggers, setTriggers] = useState<string[]>([]);

  useEffect(() => {
    if (selectedClue) {
      setValue(selectedClue.value);
      setPublicAfter(selectedClue.public_after);
      setHasBeenResolvedBy(selectedClue.first_resolver);
      setTriggers(selectedClue.triggers);
    } else {
      setValue("");
      setPublicAfter("");
      setTriggers([]);
      setHasBeenResolvedBy(null);
    }
  }, [selectedClue]);

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  if (!riddle || !riddle?.clues) {
    return null;
  }

  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        bgcolor: "background.paper",
        flex: 1,
        height: "100%",
        padding: 4,
        boxShadow: 24,
      }}
    >
      <Typography
        sx={{ mb: -1, color: "text.primary", fontWeight: "700" }}
        variant="h5"
      >
        Clues
      </Typography>
      <Typography
        sx={{ mb: 3, color: "text.secondary", fontWeight: "300" }}
        variant="subtitle1"
      >
        All clues
      </Typography>

      <Grid container spacing={2}>
        {riddle?.clues.map((clue) => (
          <Clue
            onDelete={async () => {
              setIsLoading(true);
              const response = await deleteClueRequest({
                id: clue.id,
              });
              setIsLoading(false);

              if (response.error) {
                showToast({ message: response.message, type: "error" });
                return;
              }

              setRiddle((prev) => {
                if (prev) {
                  return {
                    ...prev,
                    clues: prev.clues.filter((c) => c.id !== clue.id),
                  };
                }
                return prev;
              });

              showToast({ message: "Clue deleted" });
            }}
            key={clue.id}
            onEdit={() => {
              setSelectedClue(clue);
              setModalVisible(true);
            }}
            clue={clue}
          />
        ))}

        <Box
          sx={{
            m: 1,
            p: 2,
            bgcolor: "primary.main",
            borderRadius: 2,
            position: "relative",
          }}
          className="clickable"
          onClick={() => {
            setSelectedClue(null);
            setModalVisible(true);
          }}
        >
          <Typography
            sx={{
              color: "#fff",
            }}
          >
            Add clue
          </Typography>
        </Box>
      </Grid>

      {modalVisible ? (
        <Dialog
          fullScreen={isfullScreen}
          open={modalVisible}
          onClose={() => {
            setSelectedClue(null);
            setModalVisible(false);
          }}
          closeAfterTransition
        >
          <Box
            sx={{
              minWidth: "360px",
            }}
          >
            <DialogTitle>{selectedClue ? "Edit clue" : "Add clue"}</DialogTitle>
            <DialogContent>
              {/* User */}
              <Autocomplete
                multiple
                key={triggers.length}
                sx={{ mt: 3 }}
                freeSolo={true}
                options={[]}
                // getOptionLabel={(option) => option}
                defaultValue={triggers}
                onChange={(e, values) => {
                  const futureValues = [] as any;

                  values.forEach((v: any) => {
                    if (v.includes(",")) {
                      const triggers = v.split(",");

                      triggers.forEach((t: any) => {
                        if (t.trim() !== "") {
                          futureValues.push(t);
                        }
                      });
                    } else {
                      futureValues.push(v);
                    }
                  });

                  setTriggers(futureValues);
                  // @ts-ignore
                  setValue(futureValues.join(","));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Answer and triggers"
                    placeholder="Answer will be the first trigger"
                  />
                )}
              />
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <DateTimePicker
                  ampm={false}
                  inputFormat="DD-MM-YYYY HH:mm"
                  onChange={(ev) => {
                    // @ts-ignore
                    let date = ev?.format("YYYY-MM-DD HH:mm");
                    setPublicAfter(date);
                  }}
                  value={new Date(publicAfter)}
                  renderInput={(params) => (
                    <Input {...params} fullWidth label="Public after" />
                  )}
                />
              </Box>

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                }}
              >
                <LoadingButton
                  size="large"
                  sx={{
                    flex: 1,
                  }}
                  disabled={!publicAfter || !value}
                  variant="contained"
                  loading={isLoading}
                  onClick={async () => {
                    // update
                    setIsLoading(true);

                    const response = await addOrUpdateClueRequest({
                      // @ts-ignore
                      id: selectedClue?.id || null,
                      // @ts-ignore
                      value,
                      riddle_id: riddle.id,
                      public_after: moment(publicAfter).format("YYYY-MM-DD HH:mm"),
                    });

                    setIsLoading(false);

                    if (response.error) {
                      showToast({ message: response.message, type: "error" });
                      return;
                    }

                    setRiddle((prev) => {
                      if (prev) {
                        return {
                          ...prev,
                          clues: [
                            ...prev.clues.filter(
                              (c) => c.id !== response.clue.id
                            ),
                            response.clue,
                          ],
                        };
                      }
                      return prev;
                    });

                    setValue("");
                    setPublicAfter("");
                    setTriggers([]);
                    setHasBeenResolvedBy(null);
                    setModalVisible(false);

                    showToast({ message: "Clue saved" });
                  }}
                >
                  Save
                </LoadingButton>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      ) : null}
    </Box>
  );
};

function uuid(mask = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx") {
  return `${mask}`.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default RiddleClues;

const dateToUtc = (date: string) => {
  return moment.utc(date).format("YYYY-MM-DD HH:mm");
}