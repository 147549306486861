import React, { useContext, useState } from "react";
import FullLayout from "../../layout/full/full.layout";
import { Box, Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { UserContext } from "../../context/user.context";
import * as yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { Logo } from "../../components";
import { loginUserRequest } from "../../requests/user.requests";
import { showToast } from "../../utils/showToast";
import { hasPermission } from "../../utils/utils";

const validationSchema = yup.object({
  email: yup.string().email("Invalid email").required("First name is required"),
  password: yup
    .string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum."),
});

export default function LoginScreen() {
  const [isLoading, setIsLoading] = useState(false);

  const { setUser, setToken } = useContext(UserContext);

  const handleLogin = async (values: any) => {
    setIsLoading(true);
    const result = await loginUserRequest({
      email: values.email,
      password: values.password,
    });
    setIsLoading(false);

    if (result.error) {
      return showToast({
        message: result.message,
        type: "error",
      });
    }

    if (
      hasPermission({
        userPermissions: result.user.all_permissions,
        requiredPermissions: ["all-permissions"],
      })
      ) {
        setToken(result.token);
        setUser(result.user);
        return;
      }
      
      showToast({
        message: "You don't have the required permissions to access this app.",
      type: "error",
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      await handleLogin(values);
      setIsLoading(false);
    },
  });

  return (
    <FullLayout className="login-screen">
      <Grid container spacing={0}>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          item
          sx={{
            height: "100vh",
            bgcolor: "background.over",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="container"
            sx={{
              borderRadius: 1,
              boxShadow: 24,
              bgcolor: "background.over",
              minWidth: "350px",
              maxWidth: "380px",
              padding: "0px 32px 64px 32px",
            }}
          >
            <Box
              sx={{
                paddingTop: 4,
                paddingBottom: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Logo />
            </Box>

            <Typography
              sx={{ fontWeight: "fontWeightBold" }}
              color="text.primary"
              variant="h5"
            >
              Welcome
            </Typography>

            <Typography mb={2} color="text.secondary" variant="subtitle2">
              Complete the form below to login to your account.
            </Typography>

            <TextField
              size="small"
              autoComplete="off"
              variant="outlined"
              label="Email"
              type="email"
              color="primary"
              placeholder="ionpopescu@gmail.com"
              sx={{ marginTop: 3, width: "100%" }}
              // Formik data
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              size="small"
              autoComplete="off"
              variant="outlined"
              type="password"
              label="Password"
              color="primary"
              sx={{ marginTop: 3, width: "100%" }}
              // Formik data
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />

            <Box
              sx={{ marginTop: 4, display: "flex", justifyContent: "flex-end" }}
            >
              <LoadingButton
                style={{ width: "100%" }}
                loading={isLoading}
                onClick={() => {
                  formik.handleSubmit();
                }}
                variant="contained"
              >
                Authenticate
              </LoadingButton>
            </Box>
          </Box>
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          sx={{
            background: `url("https://i.imgur.com/SOseVf4.jpg")`,
            backgroundSize: "cover",
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
              xl: "block",
            },
          }}
          item
        ></Grid>
      </Grid>
    </FullLayout>
  );
}
