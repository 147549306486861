import { customAxios } from "../context/app.context";
import { ApiResponse } from "../typescript/shared.types";

// create voucher request
export const getAllRiddlesRequest = async (payload?: {
  slug: string;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/riddle/getAllRiddles", payload);
  return response.data;
};

// addOrUpdateRiddle
export const addOrUpdateRiddleRequest = async (payload?: {
  id?: number;
  slug?: string;
  title: string;
  subtitle: string;
  background_image: string;
  character_image: string;
  character_disabled_image: string;
  question: string;
  answer: string;
  bottom_text: string;
  bottom_image: string;
  solved_title: string;
  solved_subtitle: string;
  solved_bottom_title: string;
  solved_bottom_subtitle: string;
  solved_image: string;
  active_after_date: string;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/riddle/addOrUpdateRiddle", payload);
  return response.data;
};

// deleteRiddleRequest
export const deleteRiddleRequest = async (payload?: {
  id: number;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/riddle/deleteRiddle", payload);
  return response.data;
};

// deleteClue
export const deleteClueRequest = async (payload?: {
  id: number;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/riddle/deleteClue", payload);
  return response.data;
};

export const addOrUpdateClueRequest = async (payload?: {
  id: number;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/riddle/addOrUpdateClue", payload);
  return response.data;
};

// deleteAlmostWordRequest
export const deleteAlmostWordRequest = async (payload?: {
  id: number;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/riddle/deleteAlmostWord", payload);
  return response.data;
};

// addOrUpdateAlmostWordRequest
export const addOrUpdateAlmostWordRequest = async (payload?: {
  id: number;
  riddle_id: number;
  value: string;
}): Promise<ApiResponse> => {
  const response = await customAxios.post(
    "/riddle/addOrUpdateAlmostWord",
    payload
  );
  return response.data;
};

// bulkAddAlmostWords
export const bulkAddAlmostWordsRequest = async (payload?: {
  riddle_id: number;
  value: string;
}): Promise<ApiResponse> => {
  const response = await customAxios.post(
    "/riddle/bulkAddAlmostWords",
    payload
  );
  return response.data;
};
