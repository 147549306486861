import { UserType } from "./../typescript/shared.types";
import { UAParser } from "ua-parser-js";
import { customAxios } from "../context/app.context";
import { ApiResponse } from "../typescript/shared.types";

const getDeviceInfo = () => {
  var parser = new UAParser();

  return JSON.stringify({
    platform: "desktop",
    deviceName: parser.getDevice().model,
    deviceType: parser.getDevice().type,
    brand: parser.getDevice().vendor,
    osVersion: parser.getOS().version,
    browserName: parser.getBrowser().name,
    browserVersion: parser.getBrowser().version,
  });
};

// Register user
export const registerUserRequest = async (payload: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/user/register", {
    ...payload,
    deviceInfo: getDeviceInfo(),
  });

  return response.data;
};

// Login user
export const loginUserRequest = async (payload: {
  email: string;
  password: string;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/user/login", {
    ...payload,
    deviceInfo: getDeviceInfo(),
  });
  return response.data;
};

// Forgot password
export const sendVerificationCodeRequest = async (payload: {
  email: string;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/user/sendVerificationCode", {
    ...payload,
  });

  return response.data;
};

// Logout
export const logoutUserRequest = async (): Promise<ApiResponse> => {
  const response = await customAxios.post("/user/logout");

  return response.data;
};

// Change password
export const changePasswordRequest = async (payload: {
  email: string;
  password: string;
  code: string;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/user/changePassword", {
    ...payload,
    deviceInfo: getDeviceInfo(),
  });

  return response.data;
};

// Delete user
export const deleteUserRequest = async (payload: {
  id: string | number;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/admin/users/deleteUser", payload);

  return response.data;
};

// Validate email
export const validateEmailRequest = async (payload: {
  validationCode: string;
}): Promise<ApiResponse> => {
  const response = await customAxios.post("/user/validateEmail", {
    ...payload,
  });

  return response.data;
};

// Update user
export const updateUserRequest = async (
  payload: UserType
): Promise<ApiResponse> => {

  const response = await customAxios.post("/user/update", {
    ...payload,
  });

  return response.data;
};

// Get user by id
export const getUserByIdRequest = async (payload: {
  id: string;
}): Promise<ApiResponse> => {
  const response = await customAxios.post(`/admin/users/getUsers`, payload);

  return response.data;
};
