import React, { createContext, useEffect, useMemo, useState } from "react";

import axios from "axios";
import { BASE_URL } from "../constants/app.constants";

const AppContext = createContext<any>(null);

export const customAxios = axios.create({
  baseURL: `${BASE_URL}/api`,
  timeout: 30000,
});


function AppProvider(props: any) {
  const [images, setImages] = useState<any[] | null>(null);
  const [pdfs, setPdfs] = useState<any[] | null>(null);

  // eslint-disable-next-line
  const store = {
    images,
    setImages,
    pdfs,
    setPdfs,
  };

  const storeForProvider = useMemo(() => store, [store]);
  return (
    <AppContext.Provider value={storeForProvider}>
      {props.children}
    </AppContext.Provider>
  );
}

export { AppContext };
export default AppProvider;
