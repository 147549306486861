import React from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { DRAWER_WIDTH } from "../../constants/layout.constants";

// Icons
import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArticleIcon from "@mui/icons-material/Article";
import CategoryIcon from "@mui/icons-material/Category";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SourceIcon from "@mui/icons-material/Source";
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
// Other
import { useLocation, useNavigate } from "react-router-dom";
import WithPermissions from "../../components/with-permissions/with-permission.component";
import { Logo } from "../../components";

const ListText = ({ text }: { text: string }) => {
  return (
    <ListItemText
      disableTypography
      primary={
        <Typography style={{ color: "#737B80", fontSize: "14px" }}>
          {text}
        </Typography>
      }
    />
  );
};

const listStyle = {
  mb: "4px",
  borderRadius: "4px",
};
export default function SidebarDrawer({
  mobileOpen,
  handleDrawerToggle,
}: {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}) {
  const location = useLocation();
  let navigate = useNavigate();

  const drawer = (
    <div>
      <Toolbar>
        <Logo />
      </Toolbar>
      <Divider />
      <List
        sx={{
          p: 1,
        }}
      >
        {/* Users */}
        <WithPermissions permissions={["manage-users"]}>
          <ListItem
            sx={listStyle}
            button
            onClick={() => {
              navigate("/users");
            }}
            selected={location.pathname.includes("/users")}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>

            <ListText text="Utilizatori" />
          </ListItem>
        </WithPermissions>

        <ListItem
          sx={listStyle}
          onClick={() => {
            navigate("/riddles");
          }}
          selected={location.pathname.includes("/riddles")}
          button
        >
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListText text="Riddles" />
        </ListItem>
        <ListItem
          sx={listStyle}
          onClick={() => {
            navigate("/attempts");
          }}
          selected={location.pathname.includes("/attempts")}
          button
        >
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListText text="Attempts" />
        </ListItem>


 
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            bgcolor: "background.over",
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            bgcolor: "background.over",
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
