import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/app.context";
// @ts-ignore
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";

export default function PdfViewer() {
  const { pdfs, setPdfs } = useContext<any>(AppContext);
  // @ts-ignore
  const [numPages, setNumPages] = useState<number>(0);
  // @ts-ignore
  const [pageNumber, setPageNumber] = useState(1);

  if (!pdfs) {
    return <></>;
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <Modal
      key={JSON.stringify(pdfs)}
      open={true}
      onClose={() => {
        setPdfs(null);
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          margin: "0 auto",
          background: "background.paper",
        }}
      >
        <Document
          file={{
            url: pdfs[0],
            withCredentials: true,
          }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </Box>
    </Modal>
  );
}
