import { Avatar, Box, Chip, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import SidebarLayout from "../../layout/sidebar/sidebar.layout";
import {
  DataGrid,
  GridRenderCellParams,
  GridValueGetterParams,
  GridColumns,
} from "@mui/x-data-grid";

import { showToast } from "../../utils/showToast";
import { getUsersRequest } from "../../requests/users.requests";
import { storageUrl } from "../../utils/utils";
import { AppContext } from "../../context/app.context";
import { UserType } from "../../typescript/shared.types";
import { useNavigate } from "react-router-dom";
import { CustomToolbar } from "../../components";
import useLocalStorage from "../../hooks/useLocalStorage.hook";

export default function UsersScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<UserType[]>([]);
  const { setImages } = useContext(AppContext);
  const navigation = useNavigate();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [filters, setFilter] = useLocalStorage<any>("user_filters", null);

  const loadData = async () => {
    setIsLoading(true);
    const result = await getUsersRequest();
    setIsLoading(false);
    if (result.error) {
      showToast({ type: "error", message: result.message });
    }

    setTotal(result.users.length);
    setData(result.users);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  // Columns
  const columns = useMemo(() => {
    return generateColumns({ setImages, navigation: navigation });
  }, [setImages]);

  return (
    <SidebarLayout className="dashboard">
      <Typography
        sx={{ mb: -1, color: "text.primary", fontWeight: "700" }}
        variant="h5"
      >
        Utilizatori
      </Typography>
      <Typography
        sx={{ mb: 3, color: "text.secondary", fontWeight: "300" }}
        variant="subtitle1"
      >
        Listă utilizatori
      </Typography>

      <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
        <DataGrid
          filterModel={filters || { items: [] }}
          onFilterModelChange={(model) => {
            setFilter(model);
          }}
          components={{
            Toolbar: () => <CustomToolbar />,
          }}
          loading={isLoading}
          isRowSelectable={() => false}
          rows={data}
          sx={{
            backgroundColor: "background.paper",
          }}
          columns={columns}
          pageSize={perPage}
          rowsPerPageOptions={[25, 50, 100]}
          rowCount={total}
        />
      </div>
    </SidebarLayout>
  );
}

const generateColumns = ({
  setImages,
  navigation,
}: {
  setImages: any;
  navigation: any;
}): GridColumns => {
  return [
    {
      field: "id",
      type: "number",
      headerName: "ID",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      headerName: "User name",
      field: "username",
      sortable: true,
      width: 160,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            className="clickable"
            variant="body2"
            sx={{
              color: "primary.main",
              textDecoration: "underline",
            }}
            onClick={() => {
              navigation("/user/" + params.row.id);
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "discord_id",
      headerName: "Discord Tag",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "nutaku_id",
      headerName: "Nutaku ID",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email ",
      description: "This is user email.",
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        let user = params.row as UserType;
        return (
          <Chip
            label={params.value}
            color={user?.email_verified_at ? "success" : "error"}
            variant="outlined"
          />
        );
      },
    },
  ];
};
