import { DateTimePicker, LoadingButton } from "@mui/lab";
import { Box, FormLabel, Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentCard, Input } from "../../components";
import ConfirmModal from "../../components/modules/confirm-modal/confirm-modal.module";
import Autocomplete from "@mui/material/Autocomplete";

import SidebarLayout from "../../layout/sidebar/sidebar.layout";
// import {
//   createRiddleRequest,
//   deleteRiddleRequest,
//   getRiddlesRequest,
//   getRiddleRequest,
//   updateRiddleRequest,
// } from "../../requests/riddles.requests";
import { showToast } from "../../utils/showToast";
import FileUploader from "../../components/files-uploader/file-uploader.";
import LoadingScreen from "../loading-screen/loading.screen.";
import { createFileFromPath } from "../../utils/utils";
import { RiddleType, FileType } from "../../typescript/shared.types";
import {
  addOrUpdateRiddleRequest,
  deleteRiddleRequest,
  getAllRiddlesRequest,
} from "../../requests/riddle.requests";
import RiddleClues from "./modules/RiddleClues";
import RiddleAlmostWords from "./modules/RiddleAlmostWords";

export default function RiddleScreen() {
  const [riddle, setRiddle] = useState<RiddleType>();
  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [riddlesLoading, setRiddlesLoading] = useState<boolean>(true);
  const [selectedRiddle, setSelectedRiddle] = useState<RiddleType>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  // images
  const [character_image, setCharacterImage] = useState<FileType | null>(null);
  const [character_disabled_image, setCharacterDisabledImage] =
    useState<FileType | null>(null);

  const [bottom_image, setBottomImage] = useState<FileType | null>(null);
  const [solved_image, setSolvedImage] = useState<FileType | null>(null);
  const [background_image, setBackgroundImage] = useState<FileType | null>(
    null
  );
  const [done_image, setDoneImage] = useState<FileType | null>(null);

  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: riddle?.id,
      slug: riddle?.slug,
      // title: riddle?.title,
      // subtitle: riddle?.subtitle,
      background_image: riddle?.background_image,
      done_image: riddle?.done_image,
      character_image: riddle?.character_image,
      character_disabled_image: riddle?.character_disabled_image,
      question: riddle?.question,
      answer: riddle?.answer,
      // bottom_text: riddle?.bottom_text,
      bottom_image: riddle?.bottom_image,
      solved_title: riddle?.solved_title,
      solved_subtitle: riddle?.solved_subtitle,
      solved_bottom_title: riddle?.solved_bottom_title,
      solved_bottom_subtitle: riddle?.solved_bottom_subtitle,
      solved_image: riddle?.solved_image,
      active_after_date: riddle?.active_after_date,
      triggers: riddle?.triggers || [],
      youtube_link: riddle?.youtube_link || "",
    },
    onSubmit: async (values) => {
      // Update
      setIsLoading(true);
      // @ts-ignore

      const newData = JSON.parse(JSON.stringify(values));

      if (newData.triggers.length > 0) {
        newData.answer = newData.triggers.join(",");
      }

      const result = await addOrUpdateRiddleRequest(newData);
      setIsLoading(false);
      if (result.error) {
        return showToast({
          message: result.message,
          type: "error",
        });
      }
      navigate(`/riddle/${result.riddle.slug}`);
      return showToast({
        message: `Riddle ${slug ? "updated" : "added"}`,
        type: "success",
      });
    },
  });

  const fetchRiddle = async () => {
    setRiddlesLoading(true);
    const result = await getAllRiddlesRequest({ slug: slug || "" });
    setRiddlesLoading(false);

    if (result.error) {
      navigate("/riddles");
      return showToast({ type: "error", message: result.message });
    }

    if (result.riddles.length === 0) {
      showToast({ type: "error", message: "The riddle does not exist" });
      return navigate("/riddles");
    }

    const riddle = result.riddles[0];

    // setVariants(result.riddle.variants || []);
    setRiddle(riddle);
    setBackgroundImage(createFileFromPath(riddle.background_image));
    setCharacterImage(createFileFromPath(riddle.character_image));
    setCharacterDisabledImage(
      createFileFromPath(riddle.character_disabled_image)
    );
    setBottomImage(createFileFromPath(riddle.bottom_image));
    setSolvedImage(createFileFromPath(riddle.solved_image));
    setDoneImage(createFileFromPath(riddle.done_image));
    // setImage(createFileFromPath(result.riddle.image));
  };

  useEffect(() => {
    if (slug) {
      fetchRiddle();
    } else {
      setRiddlesLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  if (riddlesLoading) {
    return <LoadingScreen />;
  }

  return (
    <SidebarLayout className="dashboard">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            sx={{ mb: -1, color: "text.primary", fontWeight: "700" }}
            variant="h5"
          >
            {slug ? "Edit" : "Add"} riddle
          </Typography>
          <Typography
            sx={{ mb: 3, color: "text.secondary", fontWeight: "300" }}
            variant="subtitle1"
          >
            All riddle details
          </Typography>
        </Box>

        <Box>
          {riddle ? (
            <LoadingButton
              sx={{
                mr: 2,
              }}
              type="submit"
              variant="contained"
              color="error"
              loading={isLoading}
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            >
              Delete
            </LoadingButton>
          ) : null}
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {slug ? "Save" : "Add"}
          </LoadingButton>
        </Box>
      </Box>

      <Grid
        container
        spacing={0}
        gap={1}
        sx={{
          mb: 4,
          justifyContent: "space-between",
        }}
      >
        {/* Left  */}
        <Grid item xs={12} xl={4} sm={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: "background.paper",
              flex: 1,
              height: "100%",
              gap: 2,
              padding: 4,
              boxShadow: 24,
            }}
          >
            <FileUploader
              label="Blocked image"
              photoCategory="riddles"
              setFile={(e: FileType | null) => {
                // Formik set value

                formik.setFieldValue("character_disabled_image", e?.path || "");

                setCharacterDisabledImage(e);
              }}
              file={character_disabled_image}
            />

            <FileUploader
              label="Active image"
              photoCategory="riddles"
              setFile={(e: FileType | null) => {
                // Formik set value

                formik.setFieldValue("background_image", e?.path || "");

                setBackgroundImage(e);
              }}
              file={background_image}
            />

            <FileUploader
              label="Done image"
              photoCategory="riddles"
              setFile={(e: FileType | null) => {
                // Formik set value

                formik.setFieldValue("done_image", e?.path || "");

                setDoneImage(e);
              }}
              file={done_image}
            />

            <FileUploader
              label="Character image"
              photoCategory="riddles"
              setFile={(e: FileType | null) => {
                // Formik set value

                formik.setFieldValue("character_image", e?.path || "");

                setCharacterImage(e);
              }}
              file={character_image}
            />

            <FileUploader
              label="Call to action (bottom) image"
              photoCategory="riddles"
              setFile={(e: FileType | null) => {
                // Formik set value

                formik.setFieldValue("bottom_image", e?.path || "");

                setBottomImage(e);
              }}
              file={bottom_image}
            />
            <FileUploader
              label="Reward image"
              photoCategory="riddles"
              setFile={(e: FileType | null) => {
                // Formik set value

                formik.setFieldValue("solved_image", e?.path || "");

                setSolvedImage(e);
              }}
              file={solved_image}
            />
          </Box>
        </Grid>

        {/* Right */}
        <Grid
          sx={{
            minWidth: "350px",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: 2,
          }}
          item
          xs={12}
          xl={7.8}
          sm={7.8}
        >
          <Box>
            <Box
              sx={{
                mt: 2,
              }}
            >
              <DateTimePicker
                inputFormat="DD-MM-YYYY HH:mm"
                ampm={false}
                onChange={(ev) => {
                  // @ts-ignore
                  let date = ev?.format("YYYY-MM-DD HH:mm");
                  formik.setFieldValue("active_after_date", date);
                }}
                value={new Date(formik?.values?.active_after_date || "")}
                renderInput={(params) => (
                  <Input {...params} fullWidth label="Public after" />
                )}
              />
            </Box>

            {/* Titlu */}
            {/* <Input
              fullWidth
              label="Title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              sx={{ mt: 3 }}
            /> */}

            {/* Subtitlu */}
            {/* <Input
              fullWidth
              label="Subtitle"
              name="subtitle"
              onChange={formik.handleChange}
              value={formik.values.subtitle}
              sx={{ mt: 3 }}
            /> */}

            <Input
              fullWidth
              label="Youtube link"
              name="youtube_link"
              onChange={formik.handleChange}
              value={formik.values.youtube_link}
              sx={{ mt: 3 }}
            />

            <Input
              fullWidth
              label="Question"
              name="question"
              onChange={formik.handleChange}
              value={formik.values.question}
              sx={{ mt: 3 }}
            />

            <Autocomplete
              multiple
              key={formik.values.triggers.length}
              sx={{ mt: 3 }}
              freeSolo={true}
              options={[]}
              // getOptionLabel={(option) => option}
              defaultValue={formik.values.triggers}
              onChange={(e, values) => {
                const futureValues = [] as any;

                values.forEach((v: any) => {
                  if (v.includes(",")) {
                    const triggers = v.split(",");

                    triggers.forEach((t: any) => {
                      if (t.trim() !== "") {
                        futureValues.push(t);
                      }
                    });
                  } else {
                    futureValues.push(v);
                  }
                });

                formik.setFieldValue("triggers", futureValues);
                // @ts-ignore
                formik.setFieldValue("answer", futureValues.join(","));

                // const values
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Answer and triggers"
                  placeholder="Answer will be the first trigger"
                />
              )}
            />

            {/* <Input
              fullWidth
              label="Bottom text"
              name="bottom_text"
              onChange={formik.handleChange}
              value={formik.values.bottom_text}
              sx={{ mt: 3 }}
            /> */}

            <Input
              fullWidth
              label="Solved title"
              name="solved_title"
              onChange={formik.handleChange}
              value={formik.values.solved_title}
              sx={{ mt: 3 }}
            />

            <Input
              fullWidth
              label="Solved subtitle"
              name="solved_subtitle"
              onChange={formik.handleChange}
              value={formik.values.solved_subtitle}
              sx={{ mt: 3 }}
            />

            <Input
              fullWidth
              label="Solved bottom title"
              name="solved_bottom_title"
              onChange={formik.handleChange}
              value={formik.values.solved_bottom_title}
              sx={{ mt: 3 }}
            />

            <Input
              fullWidth
              label="Solved bottom subtitle"
              name="solved_bottom_subtitle"
              onChange={formik.handleChange}
              value={formik.values.solved_bottom_subtitle}
              sx={{ mt: 3 }}
            />
          </Box>
        </Grid>
        {/* Documents */}
      </Grid>
      {/* @ts-ignore */}
      <RiddleClues riddle={riddle} setRiddle={setRiddle} />
      {/* @ts-ignore */}
      <RiddleAlmostWords riddle={riddle} setRiddle={setRiddle} />

      {/* Variante */}
      <ConfirmModal
        title="Are you sure?"
        onConfirm={async () => {
          setIsLoading(true);
          const result = await deleteRiddleRequest({
            id: riddle!.id,
          });
          setIsLoading(false);

          if (result.error) {
            return showToast({ type: "error", message: result.message });
          }

          navigate("/riddles");
          showToast({ type: "success", message: "Riddle deleted" });
        }}
        isLoading={isLoading}
        visible={deleteModalOpen}
        setVisible={setDeleteModalOpen}
      >
        <p>
          You are about to delete the riddle <b>{riddle?.title}</b>
        </p>
      </ConfirmModal>
    </SidebarLayout>
  );
}
